import { Alert, Button, Divider, Form, Pagination, Popconfirm, Space, Table, Typography, notification } from 'antd'
import { useGetCategoriesLazyQuery } from 'graphql/autogenerated'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { useHistory } from 'react-router-dom'
import '../style.scss'

import { ListWithOptions } from 'app/components/list-with-options'
import { selectMe } from 'app/redux/user/selectors'
import { useDeleteCategoryMutation, useGetDeletedCategoriesLazyQuery } from 'graphql/autogenerated'
import moment from 'moment'
import { useSelector } from 'react-redux'
import { DEFAULT_PAGINATION, MESSAGES, TABLE_DROPDOWN_OPTIONS } from 'utils/constants'
import { RolesConstants } from 'utils/roles'
import { getPathByName } from 'utils/utils'
import { useRecoverCategoryMutation } from '../../../../graphql/autogenerated'

const { Title } = Typography
export function ListCategoryPage() {
  const me = useSelector(selectMe)
  const [form] = Form.useForm()
  const history = useHistory()
  const [page, setPage] = useState(1)
  const [fetchGetCategories, { data: categoriesData, loading: categoriesLoading, error: categoriesError, refetch: categoriesRefetch }] = useGetCategoriesLazyQuery({
    fetchPolicy: 'no-cache',
  })
  const [fetchGetCategoriesDeleted, { data: categoriesDeletedData, loading: categoriesDeletedLoading, error: categoriesDeletedError }] = useGetDeletedCategoriesLazyQuery({
    fetchPolicy: 'no-cache',
  })
  const [fetchDeleteCategory, { data: deletingData, loading: deletingLoading, error: deletingError, called: deletingCalled }] = useDeleteCategoryMutation({ fetchPolicy: 'no-cache' })
  const [fetchRecoverCategory, { data: recoverCategoryData, loading: recoverCategoryLoading, error: recoverCategoryError, called: recoverCategoryCalled }] = useRecoverCategoryMutation({
    fetchPolicy: 'no-cache',
  })
  const [dropdownOpt, setDropdownOpt] = useState(TABLE_DROPDOWN_OPTIONS.active.value)

  const isCategoryActive = useMemo(() => {
    return dropdownOpt === TABLE_DROPDOWN_OPTIONS.active.value
  }, [dropdownOpt])

  const fetchList = () => {
    switch (dropdownOpt) {
      case TABLE_DROPDOWN_OPTIONS.active.value: {
        fetchGetCategories({
          variables: {
            input: {
              pagination: { limit: DEFAULT_PAGINATION.pageSize!, page: page, totalCount: categoriesData?.getCategories.pagination.totalCount || null },
            },
          },
        })
        return
      }
      case TABLE_DROPDOWN_OPTIONS.deleted.value: {
        fetchGetCategoriesDeleted({
          variables: {
            input: {
              pagination: { limit: DEFAULT_PAGINATION.pageSize!, page: page, totalCount: categoriesDeletedData?.getDeletedCategories.pagination.totalCount },
            },
          },
        })
        return
      }
    }
  }

  useEffect(() => {
    fetchList()
  }, [dropdownOpt, page])

  useEffect(() => {
    if (!deletingCalled || deletingLoading) return
    const notiFnc = deletingError ? notification.error : notification.success
    notiFnc({ message: deletingError ? MESSAGES.deleteFailed : MESSAGES.deleteSuccessfully })
    fetchList()
  }, [deletingData, deletingError, deletingLoading, deletingCalled])

  useEffect(() => {
    if (!recoverCategoryCalled || recoverCategoryLoading) return
    const notiFnc = recoverCategoryError ? notification.error : notification.success
    notiFnc({
      message: recoverCategoryError ? MESSAGES.recoverFailed : MESSAGES.recoverSuccessfully,
    })
    fetchList()
  }, [recoverCategoryData, recoverCategoryError, recoverCategoryLoading, recoverCategoryCalled])

  const handleEdit = useCallback(
    record => {
      const pathname = getPathByName('category-edit', { id: record.id })
      history.push({ pathname })
    },
    [history],
  )

  const handleChangePage = page => {
    setPage(page)
  }

  const handleDelete = id => {
    fetchDeleteCategory({
      variables: {
        input: { id: id },
      },
    })
  }

  const handleRecoverCategory = (id: string) => {
    fetchRecoverCategory({
      variables: {
        input: {
          id: id,
        },
      },
    })
  }

  const tableResource = useMemo(() => {
    return (isCategoryActive ? categoriesData?.getCategories.data : categoriesDeletedData?.getDeletedCategories.data) || ([] as any)
  }, [isCategoryActive, categoriesData, categoriesDeletedData])

  const totalRecord = useMemo(() => {
    return (isCategoryActive ? categoriesData?.getCategories.pagination.totalCount : categoriesDeletedData?.getDeletedCategories.pagination.totalCount) || ([] as any)
  }, [isCategoryActive, categoriesData, categoriesDeletedData])

  const columns = [
    {
      title: 'Tên danh mục',
      dataIndex: 'name',
      key: 'name',
    },
    {
      title: 'Mô tả',
      dataIndex: 'description',
      key: 'description',
    },
    {
      title: 'Ngày tạo',
      dataIndex: 'createdAt',
      key: 'createdAt',
      render: (time, record) => {
        return <div>{moment(time).format('MM-DD-YYYY HH:mm:ss')}</div>
      },
    },
    {
      title: 'Cập nhật',
      dataIndex: 'updatedAt',
      key: 'updatedAt',
      render: (time, record) => {
        return <div>{moment(time).format('MM-DD-YYYY HH:mm:ss')}</div>
      },
    },
    {
      title: 'Hành động',
      key: 'Action',
      render: (_, record) => {
        if (isCategoryActive) {
          return (
            <Space size={'middle'}>
              <Button type="primary" onClick={() => handleEdit(record)}>
                Sửa
              </Button>
              <Popconfirm title="Bạn có chắc xóa chuyên mục này không?" onConfirm={() => handleDelete(record.id)}>
                <Button type="primary">Xóa</Button>
              </Popconfirm>
            </Space>
          )
        }
        return (
          <Space size="middle">
            <Button type="primary" onClick={() => handleRecoverCategory(record.id)}>
              Khôi phục
            </Button>
          </Space>
        )
      },
    },
  ]
  return (
    <div style={{ paddingTop: 20, paddingBottom: 20 }}>
      <Title>Danh sách chuyên mục</Title>
      <Divider plain></Divider>
      {!categoriesLoading && categoriesError && <Alert type="error" message={categoriesError?.message} banner />}
      <ListWithOptions isDeletedList={!!me.scp?.includes(RolesConstants.getCategoriesWasDeleted)} onChange={setDropdownOpt} />
      <Form form={form} component={false}>
        <Table dataSource={tableResource} loading={categoriesLoading} pagination={false} columns={columns} rowKey="id" />
      </Form>
      <div style={{ marginTop: 5 }}>
        <Pagination current={page} onChange={handleChangePage} pageSize={DEFAULT_PAGINATION.pageSize} total={totalRecord} />
      </div>
    </div>
  )
}
