import { DashboardOutlined, LogoutOutlined, SecurityScanOutlined } from '@ant-design/icons'
import { MenuProps } from 'antd'
import React from 'react'
import { NavigationType } from 'types/Utils'
import { authentication } from './authentication'
import { generateSlug } from './helpers'
type MenuItem = Required<MenuProps>['items'][number]

export const navigationConfig: NavigationType[] = [
  {
    label: 'bảng điều khiển',
    path: '/',
    icon: <DashboardOutlined />,
  },
  {
    label: 'Thành viên',
    icon: <SecurityScanOutlined />,
    children: [
      {
        label: 'Tất cả thành viên',
        path: '/user/list',
        // scp: RolesConstants.getUsers,
      },
    ],
  },
  {
    label: 'Quyền',
    // icon: <SecurityScanOutlined />,
    children: [
      {
        label: 'Tất cả quyền',
        path: '/role/list',
        // scp: RolesConstants.getRoles,
      },
      {
        label: 'Tạo nhóm quyền',
        path: '/role/add',
        // scp: RolesConstants.createRole,
      },
    ],
  },
  {
    label: 'Báo cáo',
    icon: <SecurityScanOutlined />,
    children: [
      {
        label: 'Danh sách báo cáo',
        path: '/report-ticket/list',
        // scp: RolesConstants.getReportTickets,
      },
    ],
  },
  {
    label: 'Chuyên mục',
    icon: <SecurityScanOutlined />,
    children: [
      {
        label: 'Tất cả các chuyên mục',
        path: '/category/list',
        // scp: CategoriesConstants.getCategories,
      },
      {
        label: 'Tạo chuyên mục',
        path: '/category/add',
        // scp: CategoriesConstants.createCategory,
      },
    ],
  },
  {
    label: 'Quản lý tag',
    icon: <SecurityScanOutlined />,
    children: [
      {
        label: 'Tất cả các tag',
        path: '/tag/list',
      },
      {
        label: 'Tạo tag',
        path: '/tag/add',
      },
    ],
  },
  {
    label: 'Quản lý blog',
    icon: <SecurityScanOutlined />,
    children: [
      {
        label: 'Tất cả các blog',
        path: '/blog/list',
        // scp: blogsConstants.getblogs,
      },
      {
        label: 'Tạo blog',
        path: '/blog/add',
        // scp: blogConstants.createBlog,
      },
    ],
  },
  {
    label: 'Quản lý listener',
    icon: <SecurityScanOutlined />,
    children: [
      {
        label: 'Danh sách ứng cử viên',
        path: '/listener/candidate/list',
      },
      {
        label: 'Danh sách listeners',
        path: '/listener/list',
      },
    ],
  },
  {
    label: 'Bình luận',
    icon: <SecurityScanOutlined />,
    children: [
      {
        label: 'Tất cả bình luận',
        path: '/comment/list',
        // scp: commnentsConstants.getcommnents,
      },
    ],
  },

  {
    label: 'Người dùng đã bị cấm',
    icon: <SecurityScanOutlined />,
    children: [
      {
        label: 'Tất cả người dùng dùng đã bị cấm',
        path: '/banned-user/list',
        // scp: commnentsConstants.getcommnents,
      },
    ],
  },
  {
    label: 'Danh mục khóa học',
    icon: <SecurityScanOutlined />,
    children: [
      {
        label: 'Tất cả danh mục khóa học',
        path: '/course-category/list',
      },
      {
        label: 'Tạo danh mục khóa học',
        path: '/course-category/add',
      },
    ],
  },
  {
    label: 'Khóa học',
    icon: <SecurityScanOutlined />,
    children: [
      {
        label: 'Tất cả khóa học',
        path: '/course/list',
      },
      {
        label: 'Tạo khóa học',
        path: '/course/add',
      },
    ],
  },

  {
    label: 'Logout',
    icon: <LogoutOutlined />,
    callback: () => {
      authentication.logout()
    },
  },
]
export const navigationMap = new Map()
export const transformNavigationToMenu = (
  navigation: NavigationType,
  { index, type, prefix }: { index?: string; type?: string; prefix?: string } = { index: '0', type: undefined, prefix: '' },
): MenuItem => {
  const { icon, label, path, scp, callback } = navigation
  const key = prefix + (navigation.path! || index!)
  const menu = {
    icon: icon || null,
    label,
    key: key,
    type: type as unknown as any,
    props: {
      path: path || index,
      callback: callback,
      scp: scp,
    },
  } as any
  navigationMap.set(key, menu)

  return menu
}

export const getNavigation = () => {
  return navigationConfig.map((item, index) => {
    const key = generateSlug({
      baseSlug: item.label,
    })
    return {
      ...transformNavigationToMenu(item, { index: index.toString(), prefix: key }),
      ...(item.children ? { children: item.children.map(child => transformNavigationToMenu(child, { prefix: key })) } : {}),
    }
  }) as unknown as MenuItem[]
}
