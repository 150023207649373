import { Alert, Button, Card, Col, Collapse, DatePicker, Form, Input, Modal, notification, Radio, Row, Select, Spin, Tabs, TabsProps, Typography } from 'antd'
import CollapsePanel from 'antd/lib/collapse/CollapsePanel'
import TextArea from 'antd/lib/input/TextArea'
import { Editor } from 'app/components/editor'
import { ExtractHeadingContent } from 'app/components/extract-heading-content'
import { PreviewBlog } from 'app/components/preview/preview-blog'
import { AvatarUpload } from 'app/components/uploads/AvatarUpload'
import { useGetBlogQuery, useGetCategoriesQuery, useGetTagsQuery, useUpdateBlogMutation } from 'graphql/autogenerated'
import isEmpty from 'lodash/isEmpty'
import React, { useEffect, useMemo, useRef, useState } from 'react'
import { RouteComponentProps } from 'react-router-dom'
import { DATE_TIME_FORMAT, MESSAGES } from 'utils/constants'
import { getChangedData } from 'utils/data'
import dayjs from 'utils/dayjs'
import { generateSlug, slugRegex } from 'utils/helpers'
import { transformItemsToListSelect, transformListSelectToCategories, transformListSelectToTags } from 'utils/transform'

const { Title } = Typography
const { Option } = Select

export type EditBlogPageProps = RouteComponentProps<{ id: string }, {}, { isFetchData?: boolean }> & {}
export function EditBlogPage({ match }: EditBlogPageProps) {
  const id = match.params.id
  const {
    data: blogData,
    loading: blogLoading,
    error: blogError,
  } = useGetBlogQuery({
    fetchPolicy: 'no-cache',
    variables: {
      input: {
        id: id,
      },
    },
  })
  const [form] = Form.useForm()
  const blogRef = useRef<Record<string, any>>({})

  const [fetchUpdateBlog, { loading: updateLoading, error: updateError }] = useUpdateBlogMutation({})

  const { data: categoriesData, loading: categoriesLoading } = useGetCategoriesQuery({
    fetchPolicy: 'no-cache',
  })

  const { data: tagsData, loading: getTagsLoading } = useGetTagsQuery({
    fetchPolicy: 'no-cache'
  })
  const [previewModal, setPreviewModal] = useState(false)
  const [previewHeadingContent, setPreviewHeadingContent] = useState(false)

  const convertToOptionForCategory = (list: any) => {
    return (list || []).map(item => {
      return (
        <Option value={item.id} key={item.id}>
          {item.name}
        </Option>
      )
    })
  }
  const convertToOptionForTag = (list: any) => {
    return (list || []).map(item => {
      return (
        <Option value={item.id} key={item.id}>
          {item.name}
        </Option>
      )
    })
  }

  const categories = useMemo(() => categoriesData?.getCategories.data || [], [categoriesData])
  const tags = useMemo(() => tagsData?.getTags.data || [], [tagsData])

  const renderCategoriesOpts = useMemo(() => {
    return convertToOptionForCategory(categories)
  }, [categories])

  const renderTagsOpts = useMemo(() => {
    return convertToOptionForTag(tags)
  }, [tags])

  const handleUpdate = async () => {
    try {
      const { isCustomSlug, ...row } = await form.validateFields()

      const changed = getChangedData(blogRef.current, row)
      if (isEmpty(changed)) return

      fetchUpdateBlog({
        variables: {
          input: {
            ...changed,
            id,
            ...(changed.publishDate ? { publishDate: dayjs(changed.publishDate).toISOString() } : {}),
            ...(Array.isArray(changed.tags) ? { tags: transformListSelectToTags(changed.tags) } : {}),
            ...(Array.isArray(changed.categories) ? { categories: transformListSelectToCategories(changed.categories) } : {}),
          },
        },
      })
        .then(() => {
          notification.success({
            message: MESSAGES.updateSuccessfully,
          })
          // TODO: impl re-render instead of reload page later
          window.location.reload()
        })
        .catch(() => {
          notification.error({
            message: MESSAGES.updateFailed,
          })
        })
    } catch (error) {}
  }

  const handleSetFormData = (values: any) => {
    blogRef.current = values
    form.setFieldsValue(values)
  }

  const handleTitleChange = event => {
    const title = event.target.value as string
    form.setFieldValue('title', title)
    const isCustomSlug = form.getFieldValue('isCustomSlug')
    if (!isCustomSlug) {
      const slug = generateSlug({ baseSlug: title })
      form.setFieldValue('slug', slug)
      form.validateFields(['slug']);
    }
  }

  const items: TabsProps['items'] = [
    {
      key: 'SEO',
      label: 'SEO',
      children: (
        <>
          <Form.Item label="Tiêu đề" hasFeedback name={'seoTitle'} rules={[{}]}>
            <Input placeholder="Thêm tiêu đề" />
          </Form.Item>

          <Form.Item label="Thẻ mô tả" hasFeedback name="seoDescription">
            <Input placeholder="Thêm mô tả"></Input>
          </Form.Item>
          <Form.Item label="Thumbnail" name={'seoThumbnailImage'}>
            <AvatarUpload
              onUpload={img => {
                form.setFieldsValue({
                  seoThumbnailImage: img.url,
                })
              }}
              defaultImgUrl={form.getFieldValue('seoThumbnailImage')}
            />
          </Form.Item>
          <Form.Item label="Danh sách từ khoá" name={'keyword'} rules={[{ type: 'array' }]}>
            <Select mode="tags" placeholder="Nhập từ khoá" allowClear={true} dropdownStyle={{ visibility: 'hidden' }}></Select>
          </Form.Item>
        </>
      ),
    },
    {
      key: 'Social media',
      label: 'Mạng xã hội',
      children: (
        <Collapse defaultActiveKey={'SEO Facebook'}>
          <CollapsePanel key={'SEO Facebook'} header={'Facebook preview'}>
            <Form.Item label="Tiêu đề Facebook" hasFeedback name={'facebookTitle'} rules={[{}]}>
              <Input placeholder="Thêm tiêu đề Facebook" />
            </Form.Item>
            <Form.Item label="Mô tả Facebook" hasFeedback name="facebookDescription">
              <Input placeholder="Thêm mô tả Facebook"></Input>
            </Form.Item>
            <Form.Item label="Thumbnail Facebook" name={'facebookThumbnailImage'}>
              <AvatarUpload
                onUpload={img => {
                  form.setFieldsValue({
                    facebookThumbnailImage: img.url,
                  })
                }}
                defaultImgUrl={form.getFieldValue('facebookThumbnailImage')}
              />
            </Form.Item>
          </CollapsePanel>
          <CollapsePanel key={'SEO Twitter'} header={'Twitter preview'}>
            <Form.Item label="Tiêu đề Twitter" hasFeedback name={'twitterTitle'} rules={[{}]}>
              <Input placeholder="Thêm tiêu đề Twitter" />
            </Form.Item>
            <Form.Item label="Mô tả Twitter" hasFeedback name="twitterDescription">
              <Input placeholder="Thêm mô tả Twitter"></Input>
            </Form.Item>
            <Form.Item label="Thumbnail Twitter" name={'twitterThumbnailImage'}>
              <AvatarUpload
                onUpload={img => {
                  form.setFieldsValue({
                    twitterThumbnailImage: img.url,
                  })
                }}
                defaultImgUrl={form.getFieldValue('twitterThumbnailImage')}
              />
            </Form.Item>
          </CollapsePanel>
        </Collapse>
      ),
    },
  ]

  useEffect(() => {
    const blog = blogData?.getBlog.data
    if (!blog) return
    const blogState = {
      title: blog.title,
      content: blog.content,
      description: blog.description || '',
      slug: blog.slug,
      thumbnail: blog.thumbnail,
      tags: transformItemsToListSelect(blog.tags),
      categories: transformItemsToListSelect(blog.categories),
      seoTitle: blog.blogSeo?.seoTitle || '',
      seoDescription: blog.blogSeo?.seoDescription || '',
      seoThumbnailImage: blog.blogSeo?.seoThumbnailImage || '',
      facebookTitle: blog.blogSeo?.facebookTitle || '',
      facebookDescription: blog.blogSeo?.facebookDescription || '',
      facebookThumbnailImage: blog.blogSeo?.facebookThumbnailImage || '',
      twitterTitle: blog.blogSeo?.twitterTitle || '',
      twitterDescription: blog.blogSeo?.twitterDescription || '',
      twitterThumbnailImage: blog.blogSeo?.twitterThumbnailImage || '',
      publishDate: !!blog.publishDate ? dayjs(blog.publishDate).format(DATE_TIME_FORMAT) : null,
      status: blog.status,
      keyword: blog.blogSeo?.keyword || [],
    }
    handleSetFormData(blogState)
  }, [blogData])

  return (
    <div style={{ paddingTop: 20, paddingBottom: 20 }}>
      <Title>Chỉnh sửa blog</Title>
      {!updateLoading && !!updateError && <Alert type="error" message={updateError.message} />}
      <Spin tip="Loading..." spinning={updateLoading}>
        <Form layout="vertical" form={form} onFinish={handleUpdate} initialValues={{ publishDate: null, status: null }}>
          <Row gutter={[16, 16]}>
            <Col span={18}>
              <Form.Item style={{ marginBottom: '5px' }} label="Tiêu đề" hasFeedback name={'title'} rules={[{ required: true, message: 'vui lòng lòng nhập tiêu đề' }]}>
                <Input placeholder="Thêm tiêu đề" onChange={handleTitleChange} autoFocus={true} />
              </Form.Item>

              <Form.Item name="isCustomSlug" style={{ margin: 0 }}>
                <Radio.Group defaultValue={false}>
                  <Radio value={false}>Mặc định</Radio>
                  <Radio value={true}>Chỉnh sửa đường dẫn</Radio>
                </Radio.Group>
              </Form.Item>
              <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.isCustomSlug !== currentValues.isCustomSlug}>
                {({ getFieldValue }) => {
                  const isDisabled = !getFieldValue('isCustomSlug')
                  return (
                    <Form.Item
                      style={{ margin: 0 }}
                      hasFeedback
                      name={'slug'}
                      rules={[
                        { required: true, message: 'slug là trường bắt buộc' },
                        {
                          validator: async (_, value) => {
                            if (!value || slugRegex.test(value)) {
                              return Promise.resolve()
                            }
                            return Promise.reject(new Error('Vui lòng nhập một slug hợp lệ (chỉ chữ thường, số và dấu gạch ngang)!'))
                          },
                        },
                      ]}
                      shouldUpdate={(prevValues, currentValues) => prevValues.isCustomSlug !== currentValues.isCustomSlug}
                    >
                      <Input disabled={isDisabled} size="small" placeholder="Đường dẫn tĩnh" />
                    </Form.Item>
                  )
                }}
              </Form.Item>

              <div
                style={{
                  background: 'white',
                  overflowY: 'hidden',
                }}
              >
                <Form.Item
                  label="Nội dung"
                  name={'content'}
                  style={{
                    marginBottom: 0,
                  }}
                  rules={[{ required: true, message: 'Vui lòng nhập nội dung' }]}
                >
                  <Editor placeholder="content" onChange={content => form.setFieldValue('content', content)} />
                </Form.Item>
              </div>
              <Form.Item label="Mô tả" name="description" style={{ marginTop: 8 }}>
                <TextArea
                  style={{
                    height: '200px',
                    marginTop: 5,
                  }}
                />
              </Form.Item>

              <Collapse defaultActiveKey={'SEO'}>
                <CollapsePanel key={'SEO'} header="SEO">
                  <Tabs items={items} defaultActiveKey={'SEO'} />
                </CollapsePanel>
              </Collapse>
            </Col>

            <Col span={6}>
              <Row gutter={[16, 16]} style={{ marginBottom: 10 }}>
                <Col span={24}>
                  <Card bordered={false} title="Đăng">
                    <Col>
                      <Row gutter={[24, 24]} style={{ marginBottom: 4, marginTop: 4 }}>
                        <Col span={12}>
                          <div>Hiện thị: </div>
                        </Col>
                        <Col span={12}>
                          <Form.Item style={{ margin: 0 }} label="" hasFeedback name={'status'}>
                            <Select
                              placeholder="Chọn quyền riêng tư"
                              options={[
                                { value: 1, label: 'Công khai' },
                                { value: 0, label: 'Không công khai' },
                                { value: 2, label: 'Bản nháp' },
                              ]}
                            ></Select>
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                    <Col>
                      <Row gutter={[24, 24]} style={{ marginBottom: 4, marginTop: 4 }}>
                        <Col span={12}>
                          <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.publishDate !== currentValues.publishDate}>
                            {({ getFieldValue }) => {
                              return <div>Đăng {!getFieldValue('publishDate') ? 'ngay lập tức' : 'vào lúc:'}</div>
                            }}
                          </Form.Item>
                        </Col>
                        <Col span={12}>
                          <Form.Item
                            hasFeedback
                            name={'publishDate'}
                            getValueFromEvent={onChange => (!!onChange ? dayjs(onChange).format(DATE_TIME_FORMAT) : null)}
                            getValueProps={i => ({ value: !!i ? dayjs(i) : null })}
                            rules={[{}]}
                            label=""
                          >
                            <DatePicker showTime placement="bottomRight" format={DATE_TIME_FORMAT} placeholder={DATE_TIME_FORMAT} />
                          </Form.Item>
                        </Col>
                      </Row>
                    </Col>
                    <Form.Item>
                      <Button block={false} style={{ float: 'right', justifyContent: 'end' }} type="primary" htmlType="submit">
                        Cập nhật
                      </Button>
                      <Button block={false} style={{ float: 'right', justifyContent: 'end', marginRight: 3 }} onClick={() => setPreviewModal(true)}>
                        Preview
                      </Button>
                    </Form.Item>
                    <Form.Item>
                      <Button block={false} style={{ float: 'right', justifyContent: 'end', marginRight: 3 }} onClick={() => setPreviewHeadingContent(true)}>
                        Preview heading content
                      </Button>
                    </Form.Item>
                  </Card>
                </Col>
              </Row>

              <Row gutter={[16, 16]} style={{ marginBottom: 10 }}>
                <Col span={24}>
                  <Card bordered={false} title="Chuyên mục">
                    <Form.Item name={'categories'} rules={[{ message: 'Vui lòng chọn chuyên mục!', type: 'array' }]}>
                      <Select mode="multiple" placeholder="Chọn chuyên mục" loading={categoriesLoading}>
                        {renderCategoriesOpts}
                      </Select>
                    </Form.Item>
                  </Card>
                </Col>
              </Row>

              <Row gutter={[16, 16]} style={{ marginBottom: 10 }}>
                <Col span={24}>
                  <Card bordered={false} title="Thẻ">
                    <Form.Item name={'tags'} rules={[{ message: 'Vui lòng chọn thẻ', type: 'array' }]}>
                      <Select mode="tags" placeholder="Chọn thẻ" allowClear={true} loading={getTagsLoading}>
                        {renderTagsOpts}
                      </Select>
                    </Form.Item>
                  </Card>
                </Col>
              </Row>

              <Row gutter={[16, 16]} style={{ marginBottom: 10 }}>
                <Col span={24}>
                  <Card bordered={false} title="Thumbnail">
                    <Form.Item label="Thumbnail" name={'thumbnail'}>
                      <AvatarUpload
                        onUpload={img => {
                          form.setFieldsValue({
                            thumbnail: img.url,
                          })
                        }}
                        defaultImgUrl={form.getFieldValue('thumbnail')}
                      />
                    </Form.Item>
                  </Card>
                </Col>
              </Row>
            </Col>
          </Row>
          <Modal
            open={previewModal}
            onOk={() => setPreviewModal(false)}
            onCancel={() => setPreviewModal(false)}
            maskClosable
            afterClose={() => setPreviewModal(false)}
            cancelButtonProps={{
              style: {
                display: 'none',
              },
            }}
          >
            <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.publishDate !== currentValues.publishDate}>
              {({ getFieldValue }) => {
                return <PreviewBlog content={getFieldValue('content') || ''} />
              }}
            </Form.Item>
          </Modal>
          <Modal
            open={previewHeadingContent}
            onOk={() => setPreviewHeadingContent(false)}
            onCancel={() => setPreviewHeadingContent(false)}
            maskClosable
            afterClose={() => setPreviewHeadingContent(false)}
            cancelButtonProps={{
              style: {
                display: 'none',
              },
            }}
          >
            <Form.Item noStyle shouldUpdate={(prevValues, currentValues) => prevValues.publishDate !== currentValues.publishDate}>
              {({ getFieldValue }) => {
                return <ExtractHeadingContent selectorOrHtml={getFieldValue('content') || ''} />
              }}
            </Form.Item>
          </Modal>
        </Form>
      </Spin>
    </div>
  )
}
